import { Container, CustomSelect, FormContainer, Label, NavBar, Overlay, ServicesOptions, Title } from '../components/contact-us/contact-us-form-elements';

import Background from '../img/contact-us-bg.jpeg';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import React from 'react';
import logo from '../img/logo.svg';
import { navigate } from 'gatsby';

const ContactUsPage = ({ data }) => {
 

  return (
    <Layout contactus={true}>
      <Container>
        <img src={Background} alt="" className="hero-background" style={{opacity: 0.6, zIndex: '-1'}} />
        <NavBar>
          <Link to="/" title="Logo">
            <img src={logo} alt="FilmChain" className="filmchain-icon" />
          </Link>
        </NavBar>
        <Overlay />
        <FormContainer>
          <Title
            className="h1-gradient"
          >
            Contact us
          </Title>
          <Label>
            {'What service are you interested in?'}{' '}
            <CustomSelect 
              options={ServicesOptions}
              classNamePrefix="fc-select"
              onChange={(e) => { navigate(`/contact-us/${e.value}`) }}
            />
          </Label>
        </FormContainer>
      </Container>
    </Layout>
  );
};


export default ContactUsPage;

